<template>
    <div>
        <div>
            <p v-for="code in scan_list">{{code}}</p>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import ranges from "@/common/mixins/ranges"
import infiniteScroll from 'vue-infinite-scroll'


export default {
    name:'payment',
    components: {
    },
    provide() {
        return {
            parent: this
        }
    },
    mixins: [ ranges ],
    directives: { infiniteScroll },
    created(){
        // 监听按键
        var code = ''
        var lastTime, nextTime // 上次时间、最新时间
        var lastCode, nextCode // 上次按键、最新按键
        document.onkeypress = (e) => {
        // 获取按键
        if (window.event) { // IE
            nextCode = e.keyCode
        } else if (e.which) { // Netscape/Firefox/Opera
            nextCode = e.which
        }
        // 如果触发了回车事件(扫码结束时间)
        if (nextCode === 13) {
            if (code.length < 3) return // 手动输入的时间不会让code的长度大于2，所以这里只会对扫码枪有

            console.log(code)
            this.getScan(code)
            // this.codeFind(code) // 获取到扫码枪输入的内容，做别的操作

            code = ''
            lastCode = ''
            lastTime = ''
            return
        }
        nextTime = new Date().getTime() // 记录最新时间
        if (!lastTime && !lastCode) { // 如果上次时间和上次按键为空
            code += e.key // 执行叠加操作
        }
        // 如果有上次时间及上次按键
        if (lastCode && lastTime && nextTime - lastTime > 30) { // 当扫码前有keypress事件时,防止首字缺失
            code = e.key
        } else if (lastCode && lastTime) {
            code += e.key
        }
        lastCode = nextCode
        lastTime = nextTime
        }
    },
    beforeDestroy(){
        document.onkeypress=function(){}
    },
    mounted(){  
    },
    data() {
        return {
            scan_list:[]
        }
    },
    methods: {
        async getScan(scan_code) {
            let res = await this.$store.dispatch('deviceScanPaymentAction', {data:{scan_code:scan_code}})
            this.scan_list.push(scan_code);
            if(res.success==true){
                this.$message.success('扫码成功。');
                let signSucc = new Audio()
                signSucc.src = require("../../../../assets/scan_success.wav");
                signSucc.play()
            }else{
                this.$message.warning('扫码失败。');
                let signSucc = new Audio()
                signSucc.src = require("../../../../assets/scan_failure.wav");
                signSucc.play()
            }
            // console.log(res);
        },
    },
}
</script>
<style lang="scss" scoped>
// .m-scrollbar {
  
// }
.list{
    height: calc(100vh - 280px);
    overflow-y: auto;
}
.material-left-nav{
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}
.material-btns{
  display: flex;
  align-items: center;
  &-icon{
      position: absolute;
      right: 10px;
      cursor: pointer;
  }
  &-count{
      position: absolute;
      right: 25px;
      font-size: 12px;
      color: #999;
  }
}
</style>
<template>
    <div>
        <div class="clearfix table-tools">
            <div class="buttons">
                <a-form layout='inline'>
                    <a-form-item>
                        <div>
                            <p>{{date}}</p>
                        </div>
                    </a-form-item>
                    <a-form-item>
                        <a-button @click="playSuccess" icon="sound">扫码播报</a-button>
                    </a-form-item>
                </a-form>
            </div>
        </div>    
        <div
            v-infinite-scroll="handleInfiniteOnLoad"
            class="demo-infinite-container"
            :infinite-scroll-disabled="busy"
            :infinite-scroll-distance="10"
        >
            <a-list :data-source="list">
            <a-list-item slot="renderItem" slot-scope="item, index">
                <a-list-item-meta :description="item.checkin_at"  @click="showProfileModal(item.student)">
                <a slot="title">{{item.student.student_name}}</a>
                <a-avatar
                    slot="avatar"
                    :src="item.student.student_avatar"
                />
                </a-list-item-meta>
                <div>
                    {{item.checkin_by}}
                </div>
            </a-list-item>
            <div v-if="loading && !busy" class="demo-loading-container">
                <a-spin />
            </div>
            </a-list>
        </div>
        <profileModal v-if="profileVisible" :studentId="student.student_id" :student_name='student.student_name'/>
    </div>
</template>

<script>
import moment from 'moment'
import profileModal from '@/views/modal/profile'
import infiniteScroll from 'vue-infinite-scroll';
import ranges from "@/common/mixins/ranges"

export default {
    name:'recall',
    components: {
        profileModal,
    },
    provide() {
        return {
            parent: this
        }
    },
    mixins: [ ranges ],
    directives: { infiniteScroll },
    created(){
        document.onblur=(e)=>{
            console.log(e); 
        }
        // 监听按键
        var code = ''
        var lastTime, nextTime // 上次时间、最新时间
        var lastCode, nextCode // 上次按键、最新按键
        document.onkeypress = (e) => {
        // 获取按键
        if (window.event) { // IE
            nextCode = e.keyCode
        } else if (e.which) { // Netscape/Firefox/Opera
            nextCode = e.which
        }
        // 如果触发了回车事件(扫码结束时间)
        if (nextCode === 13) {
            if (code.length < 3) return // 手动输入的时间不会让code的长度大于2，所以这里只会对扫码枪有

            console.log(code)
            this.getScan(code)
            // this.codeFind(code) // 获取到扫码枪输入的内容，做别的操作

            code = ''
            lastCode = ''
            lastTime = ''
            return
        }
        nextTime = new Date().getTime() // 记录最新时间
        if (!lastTime && !lastCode) { // 如果上次时间和上次按键为空
            code += e.key // 执行叠加操作
        }
        // 如果有上次时间及上次按键
        if (lastCode && lastTime && nextTime - lastTime > 30) { // 当扫码前有keypress事件时,防止首字缺失
            code = e.key
        } else if (lastCode && lastTime) {
            code += e.key
        }
        lastCode = nextCode
        lastTime = nextTime
        }
    },
    beforeDestroy(){
        if (this.timer) {
            clearInterval(this.timer);
        }
    },
    beforeMount(){
        this.currentTime();
    },
    mounted(){  

    },
    data() {
        return {
            data: [],
            loading: false,
            busy: false,
            scan_list:[],
            list:[],
            timer:'',
            date: '',
            student:{},
            profileVisible:false,
            searchParams: {
                "page": 1,
                "per-page": 10,
                "search": {
                    is_sub:0,
                },
                "sort": ''
            },
        }
    },
    methods: {
        showProfileModal(student) {
            this.student=student
            console.log(student);
            this.profileVisible = true
        },
        hideProfileModal() {
            this.profileVisible = false
        },
        currentTime() {
            this.timer=setInterval(this.formatDate, 500);
        },
        formatDate() {
            let date = new Date();
            let year = date.getFullYear(); // 年
            let month = date.getMonth() + 1; // 月
            let day = date.getDate(); // 日
            let week = date.getDay(); // 星期
            let weekArr = [ "星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六" ];
            let hour = date.getHours(); // 时
            hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
            let minute = date.getMinutes(); // 分
            minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
            let second = date.getSeconds(); // 秒
            second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
            this.date = `${year}年${month}月${day}日 ${weekArr[week]} ${hour}:${minute}:${second}`;
        },
        async handleInfiniteOnLoad() {
            let res = await this.$store.dispatch('deviceScanCheckinListAction',this.searchParams)
            if(this.searchParams.page == 1){
                this.busy = false
            }
            if(res.items.length >0){
                this.list = [...this.list,...res.items]
            }
            if(res._meta.totalCount == this.list.length){
                this.busy = true
            }
            this.pageParams = res._meta
            this.loading = false
            this.searchParams.page = res._meta.currentPage+1
        },
        playSuccess(){
            let signSucc = new Audio()
            signSucc.src = require("../../../../assets/scan_success.wav");
            signSucc.play()
        },
        playFailure(){
            let signSucc = new Audio()
            signSucc.src = require("../../../../assets/scan_failure.wav");
            signSucc.play()
        },
        async getScan(scan_code) {
            let res = await this.$store.dispatch('deviceScanCheckinAction', {scan_code:scan_code})
            this.scan_list.push(scan_code);
            if(res.success==true){
                this.$message.success('扫码成功。');
                this.list = [...res.data.student,...this.list]
                this.playSuccess();
            }else{
                this.$message.warning('扫码失败。');
                this.playFailure();
            }
            // console.log(res);
        },
    },
}
</script>
<style lang="scss" scoped>
// .m-scrollbar {
  
// }
.list{
    height: calc(100vh - 280px);
    overflow-y: auto;
}
.material-left-nav{
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}
.material-btns{
  display: flex;
  align-items: center;
  &-icon{
      position: absolute;
      right: 10px;
      cursor: pointer;
  }
  &-count{
      position: absolute;
      right: 25px;
      font-size: 12px;
      color: #999;
  }
}
</style>

<style>
.demo-infinite-container {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 24px;
  height: 500px;
  margin-bottom: 16px;
}
.demo-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}
</style>